@import '~react-toastify/dist/ReactToastify.css';

.Toastify__toast-container {
  z-index: 9999999;
  width: 19.5333rem;

  &--top-right {
    top: 4em;
  }
}
