.table-wrapper {
  padding: 0 1.4rem;
}

.rc-table {
  .rc-table-measure-row {
    visibility: collapse;
  }

  ::-webkit-scrollbar {
    width: 0.3333rem;
  }

  ::-webkit-scrollbar-track {
    background-color: tre;
    -webkit-border-radius: 0.3333rem;
    border-radius: 0.3333rem;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0.3333rem;
    border-radius: 0.3333rem;
    background: #6d6d6d;
  }

  table {
    width: 100%;
    font-size: 14px;

    th {
      border-bottom: 1px solid #d3d9e2;
      color: #333333;
      font-weight: bold;


      &:first-child {
        padding-left: 1.8rem;
      }

      span {
        padding:  0.7333rem 0 0.8rem 0.36rem;
      }
    }

    .rc-table-tbody {
      tr {
        &.selected,
        &:hover {
          background: #f7f7f7;

          td {
            .showWhenHovered {
              display: block;
              text-align: left;

              i {
                cursor: pointer;
              }
            }
          }
        }

        .showWhenHovered {
          display: none;
        }

        &.rc-table-row-level-1 {
          td {
            &:first-child {
              padding-left: 25px;
            }
          }
        }
        &.rc-table-row-level-2 {
          td {
            &:first-child {
              padding-left: 50px;
            }
          }
        }

        td {
          padding: 0.7333rem 0.8667rem 0.8rem;
        }
      }

    }
  }
}
