@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/LatoRegular.eot');
  src: local('☺'), url('../assets/fonts/LatoRegular.woff') format('woff'), url('../assets/fonts/LatoRegular.ttf') format('truetype'), url('../assets/fonts/LatoRegular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/LatoBold.eot');
  src: local('☺'), url('../assets/fonts/LatoBold.woff') format('woff'), url('../assets/fonts/LatoBold.ttf') format('truetype'), url('../assets/fonts/LatoBold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/LatoSemibold.eot');
  src: local('☺'), url('../assets/fonts/LatoSemibold.woff') format('woff'), url('../assets/fonts/LatoSemibold.ttf') format('truetype'), url('../assets/fonts/LatoSemibold.svg') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/LatoMedium.eot');
  src: local('☺'), url('../assets/fonts/LatoMedium.woff') format('woff'), url('../assets/fonts/LatoMedium.ttf') format('truetype'), url('../assets/fonts/LatoMedium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}