.header-logo {
  max-height: 56px;
  max-width: 149px;
}
.card {
  border-radius: 20px;
  border: none;
  box-shadow: -4px -4px 12px #ffffff, 4px 4px 12px rgba(147, 147, 147, 0.15);
  padding: 16px 5px;
  height: 349px;
  width: 270px;
  background-color: #f9f9f9;
}

.card-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  padding-top: 5px;
}

.card-body {
  padding-left: 5px;
  padding-top: 25px;
  padding-right: 5px;
}
.card-img,
.card-img-top {
  border-radius: 10px !important;
  width: 240px !important;
  margin: 0 auto;
}

@media (min-width: map-get($grid-breakpoints, 'sm')) {
  .header-logo {
    max-height: 56px;
    max-width: 149px;
  }

  .card-deck .card {
    flex: 1 0 auto;
    margin-bottom: 15px;
    max-width: 270px;
    border-radius: 20px;
    margin-right: 0;
  }
}

.crud-icon {
  width: 14px;
  height: 14px;
  transition: ease-in-out 0.2s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.crud-icon-general {
  width: 14px;
  height: 14px;
  transition: ease-in-out 0.2s;
  margin-bottom: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.application-button {
  padding: 16px 32px;
  background: #f9f9f9;
  box-shadow: -4px -4px 12px #ffffff, 4px 4px 12px rgba(147, 147, 147, 0.15);
  border-radius: 55px;
  border: none;
  color: #333333;
}
.report-card.card {
  border: none;
  padding: 5px 5px;
  width: 100%;
  height: auto;
  box-shadow: none;
  margin: 0 -5px;

  .card-header {
    padding: 0 0;
    background-color: #f9f9f9;
    cursor: pointer;
  }

  .card-title {
    font-weight: 400;
    padding-top: 5px;
  }

  .card-body {
    padding-left: 5px;
    padding-top: 10px;
    padding-right: 5px;
    background-color: #f2f3f5;
  }
}
.report .accordion {
  min-height: 540px;
}
